import React,{useEffect} from "react";
import './styles/manageservice.css';
function ManagedServices() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
    return (
        <>
            <div className="container row servicesHeadingContainer pb-5 mt-3">
                <div className="col"></div>
                <div className="col-lg-6 p-3 p-lg-5 text-start">
                    <h1 className="mt-3 servicesHeading" style={{ fontFamily: "outfit" }}>Managed Services</h1>
                    <p className="mt-3 fs-5">
                        At InOutTek Solutions, our Managed Services provide comprehensive, proactive support for your IT infrastructure, allowing you to focus on your core business while we handle the complexities of technology management.
                    </p>
                </div>
                <div className="col-lg-5 ps-5 m2-3">
                    <img src="../images/Services/manageservice.jpg" alt="" width="100%" />
                </div>

            </div>


            <div className="container-fluid text-start mt-5  col-lg-12  p-md-5 servicesMangeServiceConatiner"  >
                <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                    <div className="col-lg-5 p-lg-5" style={{
                        display
                            : "flex", flexDirection:"column", alignItems: "center", justifyContent: "center"
                    }}>
                        <h1 style={{ fontFamily: "outfit", fontSize: "2rem" }}>
                            Our Manpower and Technology Consulting Services
                        </h1>
                    </div>
                    <div className="col-lg-4 col-md-4  p-4 pt-5 servicesMangeServiceBox" style={{ borderColor: "green" }}>
                        <h3 className="text-center">
                            Strategic Workforce Planning
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Assessing your current and future staffing needs to ensure you have the right talent in place to achieve your business objectives.
                        </p>
                    </div>

                    <div className="col-lg-5 col-md-5 pt-5  p-4 servicesMangeServiceBox" style={{ borderColor: "red" }}>
                        <h3 className="text-center">
                            Skill Development and Training
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Offering targeted training programs to enhance your team’s skills and ensure they are equipped to leverage the latest technologies effectively.
                        </p>
                    </div>
                    <div className="col-lg-5 col-md-5 pt-5 p-4 servicesMangeServiceBox" style={{ borderColor: "orange" }}>
                        <h3 className="text-center">
                            Technology Advisory
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Providing expert guidance on selecting, implementing, and optimizing technology solutions that align with your strategic goals.
                        </p>
                    </div>
                    <div className="col-lg-5 col-md-5  pt-5 p-3  p-md-4 servicesMangeServiceBox" style={{ borderColor: "green" }}>
                        <h3 className="text-center pt-4">
                            Change Management
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Supporting you through technology transitions and organizational changes with strategies that minimize disruption and maximize adoption.
                        </p>
                    </div>

                    <div className="col-lg-5 col-md-5 pt-5 p-3 p-md-4 servicesMangeServiceBox">
                        <h3 className="text-center pt-4">
                            Process Improvement
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Identifying and implementing best practices to streamline operations, improve efficiency, and enhance overall performance.
                        </p>
                    </div>


                </div>

            </div>
        </>
    )
}

export default ManagedServices