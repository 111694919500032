import React, { useState } from "react";
import { Navbar, Container, Offcanvas, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles/navbar.css';
import './styles/font.css'

function NavbarHeader() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleClose = () => setShowOffcanvas(false);
    const handleShow = () => setShowOffcanvas(true);
    function hovers() {
        // alert("Hello")
    }
    return (
        <Navbar expand="lg" className="headerNavbar p-2 " >

            <Container fluid>
                <Navbar.Brand as={Link} to="/" style={{ fontWeight: "bolder", fontFamily: "outfit-semi" }} className="headerNavbarheading"> <img src="../images/inoutteklogo.png" alt="" width={30} /> Inouttek Solutions</Navbar.Brand>
                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
                <Navbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="end"
                    show={showOffcanvas}
                    onHide={handleClose}
                >
                    <Offcanvas.Header closeButton >

                        <Offcanvas.Title id="offcanvasNavbarLabel">
                            Inouttek Solutions
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end mx-4 flex-grow-1 pe-4">
                            <NavDropdown title="What we do" id={`offcanvasNavbarLabel-expand-lg`} className="pe-3 a" >
                                <NavDropdown.Item as={Link} to="/services" className="b" onClick={handleClose}>Devops as a Service</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/sre" onClick={handleClose}>24 x 7 SRE</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/managed-services" onClick={handleClose}>Managed Service</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/manpower" onClick={handleClose}> Manpower and Technology Consulting </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/testing" onClick={handleClose}>Software Testing</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/cloud" onClick={handleClose}>Cloud Security Engineering</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={Link} to="/" className="pe-4" onClick={handleClose}>Home</Nav.Link>
                            <Nav.Link as={Link} to="/about" className="pe-4" onClick={handleClose}>About</Nav.Link>

                            <Nav.Link as={Link} to="/intern" className="pe-4" onClick={handleClose}>Career</Nav.Link>
                            <Nav.Link as={Link} to="/" className="pe-4" onClick={handleClose}>Blog</Nav.Link>
                            <Nav.Link as={Link} to="/quiz" className="pe-4" onClick={handleClose}>Quiz</Nav.Link>
                            <Nav.Link as={Link} to="/contact" className="pe-4" onClick={handleClose}>Contact</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default NavbarHeader;
