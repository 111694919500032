import React, {useState, useEffect} from "react";
import {Container, Card, Button, Form, ProgressBar} from "react-bootstrap";
import questions from "../questions.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/QuizPage.css";
import {showAlert} from "./Alerts";

const QuizPage = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [score, setScore] = useState(0);
    const [filteredQuestions, setFilteredQuestions] = useState([]);

    useEffect(() => {
        const today = new Date().getDate(); // Get current day (1-31)
        const startIndex = (today - 1) * 5; // Calculate start index
        const endIndex = startIndex + 5; // Set end index (5 questions per day)
        setFilteredQuestions(questions.slice(startIndex, endIndex));
    }, []);

    const handleAnswerSelection = (answer, isCorrect) => {
        setSelectedAnswer(answer);
        if (isCorrect) {
            setScore(score + 1);
        }
        setShowResult(true);
    };

    const handleNextQuestion = () => {
        setSelectedAnswer(null);
        setShowResult(false);
        if (currentQuestion < filteredQuestions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {

                showAlert("Quiz completed!", `Your score: ${score}/${filteredQuestions.length}`, "")



            setCurrentQuestion(0);
            setScore(0);
        }
    };

    if (filteredQuestions.length === 0) {
        return <Container className="quiz-container"><p>No questions available for today.</p></Container>;
    }

    return (
        <Container className="quiz-container">
            <h3>Quiz of the Day - {currentQuestion + 1} out of {filteredQuestions.length}</h3>
            <Card className="quiz-card">
                <ProgressBar now={((currentQuestion + 1) / filteredQuestions.length) * 100} animated
                             className="quiz-progress"/>
                <h4 className="quiz-question">{filteredQuestions[currentQuestion]?.question}</h4>
                <Form>
                    {filteredQuestions[currentQuestion]?.options.map((option, index) => (
                        <Form.Check
                            key={index}
                            type="radio"
                            label={option.answer}
                            name="quiz"
                            className="quiz-option text-start"
                            checked={selectedAnswer === option.answer}
                            onChange={() => handleAnswerSelection(option.answer, option.isCorrect)}
                            disabled={showResult}
                        />
                    ))}
                </Form>
                {showResult && (
                    <p className={`quiz-result ${selectedAnswer && filteredQuestions[currentQuestion].options.find(opt => opt.answer === selectedAnswer)?.isCorrect ? 'text-success' : 'text-danger'}`}>
                        {selectedAnswer && filteredQuestions[currentQuestion].options.find(opt => opt.answer === selectedAnswer)?.isCorrect
                            ? "✅ Correct Answer!"
                            : "❌ Wrong Answer!"}
                    </p>
                )}
                <Button className="quiz-next-btn" variant="primary" onClick={handleNextQuestion} disabled={!showResult}>
                    Next Question
                </Button>
            </Card>
        </Container>
    );
};

export default QuizPage;