import React,{useEffect} from "react";
import './styles/cloud.css'
function Cloud() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
    return (
        <>
            <div className="container row servicesHeadingContainer my-3">
                <div className="col"></div>
                <div className="col-lg-6 p-3 p-lg-5 text-start">
                    <h1 className="mt-3 servicesHeading" style={{ fontFamily: "outfit" }}>Cloud Security Engineering</h1>
                    <p className="mt-3 fs-5">
                        At InOutTek Solutions, our Cloud Security Engineering services are dedicated to safeguarding your cloud environments and ensuring the protection of your data
                    </p>
                </div>
                <div className="col-lg-5 ps-5 m2-3 ">
                    <img src="./images/Services/cloud.jpg" alt="" width="100%" />
                </div>
            </div>
        


            <div className="container-fluid text-start mt-5  col-lg-12  p-md-5 servicesCloudConatiner"  >
                <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                    <div className="col-lg-5 p-lg-5" style={{
                        display
                            : "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
                    }}>
                        <h1 style={{ fontFamily: "outfit", fontSize: "2rem" }}>
                            Cloud Security Engineering by InOutTek Solutions
                        </h1>
                    </div>
                    <div className="col-lg-4 col-md-4  p-4 pt-5 servicesCloudBox">
                        <h3 className="text-center">
                            Risk Assessment
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Thoroughly evaluating your cloud infrastructure to identify and address potential security vulnerabilities and risks.
                        </p>
                    </div>

                    <div className="col-lg-5 col-md-5 pt-5  p-4 servicesCloudBox">
                        <h3 className="text-center">
                            Compliance Assurance
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Ensuring your cloud practices comply with industry regulations and standards, including GDPR, HIPAA, and others, to safeguard sensitive information.
                        </p>
                    </div>
                    <div className="col-lg-5 col-md-5 pt-5 p-4 servicesCloudBox">
                        <h3 className="text-center">
                            Security Architecture Design
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Designing and implementing robust security frameworks tailored to your specific cloud environment, enhancing overall security posture.
                        </p>
                    </div>
                    <div className="col-lg-5 col-md-5  pt-5 p-3  p-md-4 servicesCloudBox">
                        <h3 className="text-center pt-4">
                            Incident Management
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Providing rapid response and effective incident management to address and resolve security issues quickly, minimizing impact and downtime.
                        </p>
                    </div>

                    <div className="col-lg-5 col-md-5 pt-5 p-3 p-md-4 servicesCloudBox">
                        <h3 className="text-center pt-4">
                            Continuous Monitoring and Improvement
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Offering ongoing monitoring and regular updates to adapt to new threats and ensure your cloud environment remains secure and resilient.
                        </p>
                    </div>


                </div>

            </div>
        </>
    )
}


export default Cloud;