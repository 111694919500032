import React, { useEffect } from 'react';
import './styles/careerintern.css';
import { useNavigate } from "react-router-dom";  // If you're using React Router for navigation
import { useForm } from 'react-hook-form';
import 'react-bootstrap';

function CareerIntern() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });

    // ----------------------------------------------------------------------------------------------------------------------------

    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    async function onSubmit(data) {
        try {
            if (data.linkedIn_profile === undefined) {
                data.linkedIn_profile = "";
            }
            if (data.skills_and_experience === undefined) {
                data.skills_and_experience = "";
            }
            if (data.additional_information === undefined) {
                data.additional_information = "";
            }

            const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfJjQGrG_dwrHfIND975dCusgLzS1rHegdxual18rQI2fbvIQ/formResponse';
            const params = {
                'entry.1781500597': data.firstName,
                'entry.422362956': data.lastName,
                'entry.1640447617': data.email,
                'entry.297979220': data.phone_number,
                'entry.1078004677': data.linkedin_profile,
                'entry.2040870261': data.skills_and_experience,
                'entry.655759243': data.additional_information,
                'entry.1837593279': data.cover_letter
            };
            const queryString = new URLSearchParams(params).toString();

            // Send a POST request using fetch API without redirecting
            await fetch(formUrl + '?' + queryString, {
                method: 'POST',
                mode: 'no-cors', // Use 'no-cors' mode to prevent CORS issues
            });

            console.log(data.firstName)

            const mailtoLink = `mailto:info@inouttek.com?subject=Student Internship &body= Hi I am  ${encodeURIComponent(data.firstName)} ${encodeURIComponent(data.lastName)} %0A%0AEmail : ${encodeURIComponent(data.email)} %0APhone Number : ${encodeURIComponent(data.phone_number)} %0ALinkedIn Profile : ${encodeURIComponent(data.linkedin_profile)} %0ASkills and Experience ${encodeURIComponent(data.skills_and_experience)} %0AAdditional Information : ${encodeURIComponent(data.additional_information)} %0ACover Letter : ${encodeURIComponent(data.cover_letter)} %0A%0A Please Attach Your Resume`;
            window.location.href = mailtoLink;

            reset();


            setTimeout(() => {
                // window.location.reload();
                reset();
            }, 1000);
        } catch (error) {
            console.log(error.message);

            console.log("Error")
        }
    }




    //-------------------------------------------------------------------------------------------------------------------------------
    const backgroundImage = "/images/Career/careerImage.jpg";
    return (
        <>
            <h1 className='careerInternTitle'>Student Intern</h1>
            <div className="CareerBackgroundImage" style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: 'absolute',
                height: "75%",
                width: "100%",
                opacity: "0.1",
            }}>

            </div>
            <div className="careerContainer" style={{ position: "relative" }}>
                <div className="content m-2" >
                    <h1>Role Description</h1>
                    <p>This is a full-time on-site role for a Student Intern at InOutTek Solutions in Perundurai. The Student Intern will be involved in day-to-day tasks related to DevOps, release engineering, and automation processes. Responsibilities may include assisting in software development tasks, collaborating with team members, and gaining hands-on experience in IT solutions.</p>
                    <h1>Qualifications</h1>
                    <ul>
                        <li>Basic knowledge of software development, Intermediate knowledge of any programming language</li>
                        <li>Interest in learning and gaining experience in cloud computing, Automation and Web Development</li>
                        <li>Problem-solving skills and ability to collaborate in a team environment</li>
                        <li>Excellent communication and interpersonal skills</li>
                        <li>Ability to adapt to a fast-paced work environment</li>
                        <li>Pursuing a degree in Computer Science, Information Technology, or related field</li>
                    </ul>
                </div>

                <div className="careerForm">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="careerFormInput">
                            <div className="careername">
                                <div className="careerfirstname">
                                    <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        placeholder="First Name *"
                                        {...register('firstName', { required: 'First Name is required' })}
                                    />
                                    {errors.firstName && <span style={{ color: 'red', justifyContent: "start", display: "flex" }}>{errors.firstName.message}</span>}

                                </div>
                                <div className="careerlastname">
                                    <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        placeholder="Last Name *"
                                        {...register('lastName', { required: 'Last Name is required' })}
                                    />
                                    {errors.lastName && <span style={{ color: 'red', justifyContent: "start", display: "flex" }}>{errors.lastName.message}</span>}

                                </div>
                            </div>


                        </div>

                        <div className="careerFormInput">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="E-mail *"
                                {...register('email', {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^\S+@\S+\.\S+$/,
                                        message: 'Invalid email format'
                                    }
                                })}
                            />
                            {errors.email && <span style={{ color: 'red', justifyContent: "start", display: "flex" }}>{errors.email.message}</span>}
                        </div>

                        <div className="careerFormInput">
                            <input
                                type="text"
                                name="phone_number"
                                id="phone_number"
                                placeholder="Phone Number *"
                                {...register('phone_number', {
                                    required: 'Mobile number is required',
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: 'Invalid mobile number. Must be 10 digits.'
                                    }
                                })}
                            />
                            {errors.phone_number && <span style={{ color: 'red', justifyContent: "start", display: "flex" }}>{errors.phone_number.message}</span>}
                        </div>



                        <div className="careerFormInput">
                            <input
                                type="text"
                                name="linkedin_profile"
                                id="linkedin_profile"
                                placeholder="LinkedIn Profile "
                                {...register('linkedin_profile')}
                            />
                        </div>
                        <div className="careerFormInput">
                            <input
                                type="text"
                                name="skills_and_experience"
                                id="skills_and_experience"
                                placeholder=" Skills and Experience "
                                {...register('skills_and_experience')}
                            />
                        </div>
                        <div className="careerFormInput">
                            <input
                                type="text"
                                name="additional_information"
                                id="additional_information"
                                placeholder="Additional Information"
                                {...register('additional_information')}
                            />
                        </div>
                        <div className="careerFormInput">
                            <textarea
                                type="text"
                                name="cover_letter"
                                id="cover_letter"
                                placeholder="Cover Letter *"
                                {...register('cover_letter', { required: 'Cover Letter is required' })}
                            ></textarea>
                            {errors.cover_letter && <span style={{ color: 'red', justifyContent: "start", display: "flex" }}>{errors.cover_letter.message}</span>}

                        </div>

                        {/* <div className="careerFormInput">
                            <div class="upload-resume">

                                <button class="resumeButton ">Upload Resume *</button>
                                <input
                                    type="file"
                                    name="resume"
                                    id="resume"
                                // {...register('resume', { required: 'Resume is required' })}
                                />
                                {errors.resume && <span style={{ color: 'red', justifyContent: "start", display: "flex" }}>{errors.resume.message}</span>}
                            </div>
                        </div> */}
                        <div className="careerFormInput">
                            <button type="submit" className='submitButton'>Submit</button>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default CareerIntern;