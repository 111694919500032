import React, { useEffect } from "react";
import './styles/manpower.css'
function Manpower() {
    useEffect(() => {
        document.documentElement.scrollTop = 0;
    });
    return (
        <>
            <div className="container row servicesHeadingContainer mt-3">
                <div className="col"></div>
                <div className="col-lg-6 p-3 p-lg-5 text-start">
                    <h1 className="mt-3 servicesHeading" style={{ fontFamily: "outfit" }}>Manpower and Technology Consulting</h1>
                    <p className="mt-3 fs-5">
                        Manpower and Technology Consulting is a specialized service aimed at optimizing an organization’s human resources and technological infrastructure. The primary goal is to enhance efficiency, effectiveness, and overall performance through strategic management of people and technology. Here’s a breakdown of what this consulting involves                    </p>
                </div>
                <div className="col-lg-5 ps-5 m2-3">
                    <img src="../images/Services/manpower.jpg" alt="" width="100%" />
                </div>

            </div>





            <div className="container-fluid text-start mt-5  col-lg-12  p-md-5 servicesmanpowerConatiner"  >
                <div className="row mt-lg-4 p-4" style={{ columnGap: 10, rowGap: 50, justifyContent: "space-around" }}>
                    <div className="col-lg-5 p-lg-5" style={{
                        display
                            : "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
                    }}>
                        <h1 style={{ fontFamily: "outfit", fontSize: "2rem" }}>
                            Our Manpower and Technology Consulting Services
                        </h1>
                    </div>
                    <div className="col-lg-4 col-md-4  p-4 pt-5 servicesmanpowerBox">
                        <h3 className="text-center">
                            24/7 Monitoring and Support
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Continuous monitoring of your systems with round-the-clock support to address any issues before they impact your operations.
                        </p>
                    </div>

                    <div className="col-lg-5 col-md-5 pt-5  p-4 servicesmanpowerBox">
                        <h3 className="text-center">
                            Proactive Maintenance
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Regular updates, patch management, and performance optimization to ensure your IT environment is secure and running efficiently.
                        </p>
                    </div>
                    <div className="col-lg-5 col-md-5 pt-5 p-4 servicesmanpowerBox">
                        <h3 className="text-center">
                            Cloud Management
                        </h3>
                        <p className="text-start mt-4 ms-4 p-md-3">
                            Expert management of your cloud resources, including provisioning, scaling, and cost optimization.
                        </p>
                    </div>
                    <div className="col-lg-5 col-md-5  pt-5 p-3  p-md-4 servicesmanpowerBox">
                        <h3 className="text-center pt-4">
                            Security and Compliance
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Implementing robust security measures and ensuring compliance with industry standards to protect your data and infrastructure.
                        </p>
                    </div>

                    <div className="col-lg-5 col-md-5 pt-5 p-3 p-md-4 servicesmanpowerBox">
                        <h3 className="text-center pt-4">
                            Incident Response and Recovery
                        </h3>
                        <p className="text-start mt-4 ms-4 ">
                            Rapid response to incidents and effective disaster recovery solutions to minimize downtime and data loss.
                        </p>
                    </div>


                </div>

            </div>
        </>
    )
}
export default Manpower;