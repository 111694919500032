import React, { useEffect } from "react";
import "./styles/contact.css";
import { useForm } from 'react-hook-form';
import 'react-bootstrap';
import { useNavigate } from "react-router-dom";  // If you're using React Router for navigation
import { useState } from 'react';

function Contact() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [showAlert, setShowAlert] = useState(false);  // State to control alert visibility

  const onSubmit = async (data) => {
    console.log("Form submitted successfully", data);

    const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLScV4HcCe20J4_npvNAd4roe_souVT_uAr3mCIM-QSHzuMDeHg/formResponse';

    const params = {
      'entry.295232790': data.firstName,  // Replace with actual entry ID for "First Name"
      'entry.1083693563': data.lastName,  // Replace with actual entry ID for "Last Name"
      'entry.1818282646': data.email,     // Replace with actual entry ID for "Email"
      'entry.1788207797': data.service,   // Replace with actual entry ID for "Service"
      'entry.458705226': data.phone_number, // Replace with actual entry ID for "Phone Number"
      'entry.1793477070': data.message      // Replace with actual entry ID for "Message"
    };

    try {
      const queryString = new URLSearchParams(params).toString();

      // Send a POST request using fetch API without redirecting
      await fetch(formUrl + '?' + queryString, {
        method: 'POST',
        mode: 'no-cors', // Use 'no-cors' mode to prevent CORS issues
      });
      setShowAlert(true);
      reset();
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      // window.location.reload()
    } catch (error) {
      console.log(error.message);
      alert('Failed to submit form');
    }
  };

  return (
    <>
      <div className="contactHeading mt-3">
        <h1>Contact Us</h1>
        <p>Fill out the form below and we’ll have the right person get back in touch with you.</p>
      </div>

      <div className="contactContainer">

        <div className="contactForm">
          <div className={`alert alert-success ${showAlert ? '' : 'd-none'}`} role="alert">
            <h4 class="alert-heading">Thank you for contacting InOutTek Solutions!</h4>
            <p>We’ve received your message and will get back to you shortly.</p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="contactFormInput">
              <input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First Name"
                {...register('firstName', { required: 'First Name is required' })}
              />
              {errors.firstName && <span style={{ color: 'red', marginLeft: '7px' }}>{errors.firstName.message}</span>}
            </div>

            <div className="contactFormInput">
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
                {...register('lastName', { required: 'Last Name is required' })}
              />
              {errors.lastName && <span style={{ color: 'red', marginLeft: '7px' }}>{errors.lastName.message}</span>}
            </div>

            <div className="contactFormInput">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: 'Invalid email format'
                  }
                })}
              />
              {errors.email && <span style={{ color: 'red', marginLeft: '7px' }}>{errors.email.message}</span>}
            </div>

            <div className="contactFormInput">
              <input
                type="text"
                name="phone_number"
                id="phone_number"
                placeholder="Phone Number"
                {...register('phone_number', {
                  required: 'Mobile number is required',
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: 'Invalid mobile number. Must be 10 digits.'
                  }
                })}
              />
              {errors.phone_number && <span style={{ color: 'red', marginLeft: '7px' }}>{errors.phone_number.message}</span>}
            </div>

            <div className="contactFormInput">
              <select
                name="service"
                id="service"
                {...register('service', { required: 'Please select an option' })}
              >
                <option value="" disabled selected hidden>Choose a Service...</option>
                <option value="Software Development">Software Development</option>
                <option value="Cloud Solutions">Cloud Solutions</option>
                <option value="DevOps Consulting">DevOps Consulting</option>
                <option value="Automation">Automation</option>
                <option value="Infrastructure Management">Infrastructure Management</option>
                <option value="SRE">SRE</option>
                <option value="24/7 Support">24/7 Support</option>
                <option value="Others">Others</option>

              </select>
              {errors.service && <span style={{ color: 'red', marginLeft: '7px' }}>{errors.service.message}</span>}
            </div>

            <div className="contactFormInput">
              <textarea
                name="message"
                id="message"
                placeholder="Message..."
                {...register('message', { required: 'Message is required' })}
              ></textarea>
              {errors.message && <span style={{ color: 'red', marginLeft: '7px' }}>{errors.message.message}</span>}
            </div>

            <div className="contactFormInput">
              <button type="submit">Submit</button>
            </div>
          </form>


        </div>

        <div className="contactImage">
          <img src="/images/Contact/contact_page.png" alt="" />

        </div>

      </div>



      <div className="HomeContainerAboutWhy container-fluid row p-4" style={{ textAlign: "left", display: "flex", justifyContent:"space-around", rowGap: 10 }} >
        <div className="HomeContainerAbout col-md-6 py-5 " >
          <h2 className="ps-lg-3" style={{ fontFamily: "outfit", paddingLeft: 0 }}>Office Locations</h2>
          <ul className="ps-lg-5 pe-2">
            <li>
              Villa 11, Casa Grande Eternia - Phase 1, Kalapatti Main Road, Coimbatore, Tamil Nadu 641048
            </li>
            <li>
              PKM Complex, 3rd Floor, Opposite to old Bus stand, Perundurai, Tamil Nadu-638052
            </li>
          </ul>
        </div>
        <div className="col"></div>

        <div className="HomeContainerAbout col-md-5 py-5 " >
          <h2 className="ps-lg-3" style={{ fontFamily: "outfit", paddingLeft: 0 }}>E-mail</h2>
          <ul className="ps-lg-5 pe-2">
            <a href="mailto:info@inouttek.com" style={{ color: "black", }}>info@inouttek.com</a>

          </ul>
          <h2 className="ps-lg-3" style={{ fontFamily: "outfit", paddingLeft: 0 }}>Phone Number</h2>
          <ul className="ps-lg-5 pe-2">
            <li>
              9384163432
            </li>
            <li>
              9597863432

            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Contact;
