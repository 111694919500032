import Swal from "sweetalert2";

export const showAlert = (title, text, icon = "success") => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
        // timer: 3000, // Auto close in 3 seconds
        showClass: {
            popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
            popup: "animate__animated animate__fadeOutUp",
        },
    });
};
